<template>

<!--  校园筛查 筛查前设置  新增学校 -->
  <div class="page">
    <div class="school-form">
      <el-form :model="ruleForm" label-position="right" label-width="180px" :rules="rules" ref="ruleForm" size="mini" class="school-ruleForm el-form">
        <el-form-item label="学校所在地：" prop="placeCode">
          <el-cascader
            :options="options"
            :props="props"
            v-model="selectedOptions"
            @change="handleChange"
            @active-item-change="handleChange"
            v-bind="$attrs"
            :clearable="clearable"
            :filterable="filterable"
            :change-on-select="changeOnSelect"
            :disabled="disabled"
            :show-all-levels="showAllLevels"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="学校所属社区(街道）：" prop="address">
          <el-input maxlength="50" v-model="ruleForm.address" placeholder="请填写学校所属社区"></el-input>
        </el-form-item>
        <el-form-item label="学校名称：" prop="schoolName">
          <el-input  maxlength="11" v-model="ruleForm.schoolName" placeholder="请填写学校名称"></el-input>
        </el-form-item>
        <el-form-item label="学校类型：" prop="schoolType">
          <el-select v-model="ruleForm.schoolType" placeholder="请选择学校类型">
            <el-option
              v-for="item in natureOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校性质：" prop="schoolNature">
          <el-select v-model="ruleForm.schoolNature" placeholder="请选择学校性质">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="联系人" prop="principalName">
            <el-input maxlength="11" v-model="ruleForm.principalName"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="principalPhone">
            <el-input maxlength="11" v-model="ruleForm.principalPhone"></el-input>
          </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini"  @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="return" size="mini"  @click="returnPage()">返回</el-button>
      </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { PlaceCode } from '../../../static/js/area.js'
import schoolService from '@/globals/service/detection/school.js'

export default {

  data () {
    return {
      options: [],
      selectedOptions: [],
      keyPath: null,
      // 是否可搜索选项
      filterable: false,
      // 是否允许选择任意一级的选项 默认值false
      changeOnSelect: false,
      // 是否禁用
      disabled: false,
      clearable: true,
      // 输入框中是否显示选中值的完整路径
      showAllLevels: true,
      props: {
        value: 'value',
        label: 'name',
        children: 'children',
        disabled: 'disabled'
      },
      natureOptions: [
        { value: '0', label: '幼儿园' },
        { value: '1', label: '小学' },
        { value: '2', label: '初中' },
        { value: '3', label: '高中' },
        { value: '4', label: '大学' }
      ],
      typeOptions: [
        { value: 0, label: '公办' },
        { value: 1, label: '民办' }
      ],
      ruleForm: {
        placeCode: [],
        address: '',
        schoolName: '',
        schoolType: '',
        schoolNature: '',
        // 联系人名称
        principalName: null,
        // 联系方式
        principalPhone: null
      },
      rules: {
        placeCode: [
          { required: true, message: '请选择学校所在地', trigger: 'change' }
        ],
        schoolName: [
          { required: true, message: '请填写学校名称', trigger: 'blur' },
          { min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }
        ],
        schoolType: [
          { required: true, message: '请选择学校类型', trigger: 'change' }
        ],
        schoolNature: [
          { required: true, message: '请选择学校性质', trigger: 'change' }
        ],
        principalName: [
          { required: true, message: '请填写联系人名称', trigger: 'blur' }
        ],
        principalPhone: [
          { required: true, message: '请输入联系方式', trigger: 'change' },
          { required: true, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '目前只支持中国大陆的手机号码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  async mounted () {
    await this.find()
  },
  created () {
  },
  methods: {
    goTo (path, query) {
      this.$router.push({ path: path, query: query })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // const loading = this.$loading({
          //   lock: true,
          //   text: '加载中……',
          //   background: 'rgba(0, 0, 0, 0.7)'
          // })
          // const that = this
          const ruleForm = this.ruleForm
          const formData = {
            address: ruleForm.address,
            provinceId: ruleForm.placeCode[0] || '',
            cityId: ruleForm.placeCode[1] || '',
            areaId: ruleForm.placeCode[2] || '',
            schoolName: ruleForm.schoolName,
            schoolNature: ruleForm.schoolNature,
            schoolType: ruleForm.schoolType,
            principalName: ruleForm.principalName,
            principalPhone: ruleForm.principalPhone
          }
          schoolService.add(formData).then(res => {
            this.$alert(`账号: ${res.data.phone}; 密码: ${res.data.password}`, '请记住你的账号密码', {
              confirmButtonText: '复制账号密码',
              callback: () => {
                var input = document.createElement('input') // 直接构建input
                input.value = '账号：' + res.data.phone + ' 密码：' + res.data.password // 设置内容
                document.body.appendChild(input) // 添加临时实例
                input.select() // 选择实例内容
                document.execCommand('Copy') // 执行复制
                document.body.removeChild(input) // 删除临时实例
                this.$router.push({ path: '/detection/schoolManagement' })
              }
            })
            // loading.close()
            // this.$message({
            //   message: res.errMsg,
            //   type: 'success',
            //   duration: 1000,
            //   onClose: function () {
            //     that.$router.push({ path: '/detection/schoolManagement' })
            //   }
            // })
          })
        } else {
          this.$message.error('请填入相应信息！')
          return false
        }
      })
    },
    returnPage () {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/login' })
        return false
      } else {
        this.$router.push({ path: '/detection/schoolManagement' })
      }
    },
    async find () {
      const options = PlaceCode()
      if (Array.isArray(options)) {
        this.options = await this.newOptions(options)
        this.setSelectedOptions(this.value)
      } else {
        this.options = []
        this.selectedOptions = []
      }
    },
    setSelectedOptions (value) {
      if (value && this.findKeyPath(this.options, value)) {
        this.selectedOptions = this.keyPath
      } else {
        this.selectedOptions = []
      }
    },
    /**
     * 查找当前标识符和所有父项标识符组成的路径
     */
    findKeyPath (options, value, indexs) {
      var index = indexs
      if (index == null) {
        this.keyPath = []
        if (!options || options.length === 0 || !value) {
          return
        }
        index = 0
      }
      for (var o of options) {
        this.keyPath[index] = o.value
        if (o.value === value) {
          return true
        }
        if (o.children && o.children.length > 0) {
          if (this.findKeyPath(o.children, value, index + 1) === true) {
            return true
          }
        }
      }
    },
    /**
     * 选择
     */
    handleChange (newValue) {
      let code = ''
      if (newValue.length > 0) {
        code = newValue[newValue.length - 1]
      }
      // 区域代码
      let areaCode = code
      if (newValue.length === 3) {
        const level1 = newValue[0]
        if ('110000|120000|310000|500000'.indexOf(level1) !== -1) {
          // 直辖市的区域代码不变
        } else {
          // 省
          areaCode = newValue[1] // 省的县级市的区域代码设置为地级市的行政区划代码
          console.log(areaCode)
        }
        this.ruleForm.placeCode = newValue
      }
    },
    /**
     * 递归处理
     * 将所有的id更换为string类型
     */
    newOptions (options) {
      options.forEach(item => {
        item.value = String(item.value)
        if (item.children) {
          this.newOptions(item.children)
        }
      })
      return options
    }

  }

}
</script>
<style lang="scss" scoped>
  .school-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 24px 0;
    box-sizing: border-box;
    max-width: 600px;
  }
.header-container{
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #f5f5f5;
  .header-section{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .route-name{
      line-height: 43px;
      font-size: 12px;
      color: #51B25F;
    }
    .button-section{
      display: flex;
      align-items: center;
    }
  }
}
.page{
    padding: 20px 10px;
    background-color: #fff;
    width: 100%;
    min-height: 100%;
  }
/deep/.el-form-item__content{
  display: flex;
}
.red{
    color: #f50e0e;
  }
</style>
